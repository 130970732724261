
body {
    margin: 0;
    font-family: 'Montserrat';
    letter-spacing: 0.25rem !important;
}

.spaced-text-button {
    letter-spacing: 0.18rem !important;
    font-size: .75rem !important;
    font-weight: 400 !important;
}

img {
    vertical-align: middle;
    border-style: none;
    outline: none !important;
}

div {
    outline: none !important;
}

.show-toolbar-lg, .show-toolbar-md, .show-toolbar-sm {
    display: none !important;
}

.hero{
    height: calc(100vh - 168px);
    padding: 0px 5vw 84px;
}

.hero-contact{
    height: calc(100vh - 168px);
    padding: 5px 5vw 79px;
}

.portfolio-gallery img, .portfolio-gallery .slick-list{
    height: 50vh;
    max-height: 50vh;
}

.landing-slider .slick-slide {
    visibility: hidden;
}

.landing-slider .slick-slide.slick-active {
    visibility: visible;
}



@media only screen and (max-width: 600px) {
    .show-toolbar-sm {
        display: flex !important;
    }

    .hero{
        height: calc(75vh - 84px) !important;
        padding: 0px 5vw 84px;
    }

    .hero-contact{
        height: auto !important;
    }

    .contact-msg{
        font-size: 1rem !important;
    }

    .contact-email{
        font-size: .65rem !important;
        padding-bottom: 10px;
    }

    .portfolio-gallery img, .portfolio-gallery .slick-list{
        height: 25vh;
    }

}

a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

@media only screen and (min-width: 601px) and (max-width: 1699px) {
    .show-toolbar-md {
        display: flex !important;
    }
}

@media only screen and (min-width: 1700px) {
    .show-toolbar-lg {
        display: flex !important;
    }
}